import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safeHtmlParser";

const SupportLinksPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "support-links" }) {
					seoFields {
						title
						metaDescription
						opengraphTitle
						opengraphDescription
						localBusinessSchema
						image {
							node {
								sourceUrl
								altText
								localFile {
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					supportLinksFields {
						supportIntroSection {
							heading
							description
						}
					}
				}
			}
		`
	);

	const {
		pageData: {
			seoFields,
			supportLinksFields: { supportIntroSection },
		},
	} = data;

	const siteUrl = data.site.siteMetadata.siteUrl;
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Reviews",
				item: {
					url: `${siteUrl}/support-links`,
					id: `${siteUrl}/support-links`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "Holding Space parent stories",
						},
					],
				}}
			/>
			{supportIntroSection &&
				!checkPropertiesForNull(supportIntroSection, ["heading"]) && (
					<section className="py-5">
						<Container>
							<Row>
								<Col>
									<h1 className="text-primary">
										{supportIntroSection?.heading}
									</h1>
								</Col>
							</Row>
							<Row className="pt-3">
								<Col>
									<SafeHtmlParser
										htmlContent={supportIntroSection?.description}
									/>
								</Col>
							</Row>
						</Container>
					</section>
				)}
		</Layout>
	);
};
export default SupportLinksPage;
